import React from 'react';
import {Box , CircularProgress } from '@material-ui/core'; 
 

const Loading = (props) => {
  return (
    <Box 
    display="flex"
    flexDirection="column"
    height="70%"
    justifyContent="center"
    style ={{alignItems:"center"}}
   >
      <CircularProgress 
       size="8rem"
      disableShrink />
   </Box>
  )
};

export default Loading;
